import React from "react";

const Index: React.FC = () => {
 return (
        <>
            <div className="grid lg:grid-cols-4 mx-auto max-w-[1600px]">
                <div className="col-span-1 hidden lg:flex flex-col gap-y-2.5 pt-[25px]">
                    <div className="bg-white py-[15px] pl-5 rounded-[20px] inline-block w-[290px]">
                        <span className="font-['Roboto Flex'] font-extrabold text-sm text-black select-none">Spy-сервисы</span>
                        <div className="px-3 pt-2.5 flex flex-col gap-y-2">
                            <a href="#" className="font-['Roboto Flex'] font-regular text-sm">AdHeart</a>
                            <a href="#" className="font-['Roboto Flex'] font-regular text-sm">PiPiAds</a>
                        </div>
                    </div>
                    <div className="bg-white py-[15px] pl-5 rounded-[20px] inline-block w-[290px]">
                        <span className="font-['Roboto Flex'] font-extrabold text-sm text-black select-none">Дизайн</span>
                        <div className="px-3 pt-2.5 flex flex-col gap-y-2">
                            <a href="#" className="font-['Roboto Flex'] font-regular text-sm">Envato Elements</a>
                            <a href="#" className="font-['Roboto Flex'] font-regular text-sm">FreePik</a>
                            <a href="#" className="font-['Roboto Flex'] font-regular text-sm">Supa</a>
                            <a href="#" className="font-['Roboto Flex'] font-regular text-sm">Canva</a>
                        </div>
                    </div>
                    <div className="bg-white py-[15px] pl-5 rounded-[20px] inline-block w-[290px]">
                        <span className="font-['Roboto Flex'] font-extrabold text-sm text-black select-none">Нейросети</span>
                        <div className="px-3 pt-2.5 flex flex-col gap-y-2">
                            <a href="#" className="font-['Roboto Flex'] font-regular text-sm">ChatGPT</a>
                            <a href="#" className="font-['Roboto Flex'] font-regular text-sm">Midjourney</a>
                            <a href="#" className="font-['Roboto Flex'] font-regular text-sm">Leonardo AI</a>
                        </div>
                    </div>
                    <div className="bg-white py-[15px] pl-5 rounded-[20px] inline-block w-[290px]">
                        <span className="font-['Roboto Flex'] font-extrabold text-sm text-black select-none">Telegram</span>
                        <div className="px-3 pt-2.5 flex flex-col gap-y-2">
                            <a href="#" className="font-['Roboto Flex'] font-regular text-sm">TGstat</a>
                            <a href="#" className="font-['Roboto Flex'] font-regular text-sm">Telemetr</a>
                        </div>
                    </div>
                    <div className="bg-white py-[15px] pl-5 rounded-[20px] inline-block w-[290px]">
                        <span className="font-['Roboto Flex'] font-extrabold text-sm text-black select-none">SEO</span>
                        <div className="px-3 pt-2.5 flex flex-col gap-y-2">
                            <a href="#" className="font-['Roboto Flex'] font-regular text-sm">SerpStat</a>
                            <a href="#" className="font-['Roboto Flex'] font-regular text-sm">Semrush</a>
                        </div>
                    </div>
                    <div className="bg-white py-[15px] pl-5 rounded-[20px] inline-block w-[290px]">
                        <span className="font-['Roboto Flex'] font-extrabold text-sm text-black select-none">Парсеры</span>
                        <div className="px-3 pt-2.5 flex flex-col gap-y-2">
                            <a href="#" className="font-['Roboto Flex'] font-regular text-sm">Segmento Target</a>
                        </div>
                    </div>
                    <div className="bg-white py-[15px] pl-5 w-[290px] rounded-[20px] inline-block">
                        <div className="px-3 flex flex-col gap-y-2">
                            <a href="#" className="font-['Roboto Flex'] font-regular text-sm">
                                <span className="font-['Roboto Flex'] font-extrabold text-sm text-black select-none">Реклама на Skladchik.me</span>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-span-2 col-start-2 pt-[25px]">
                    <div className="bg-white rounded-[20px] pt-[104px] md:pt-[220px] lg:pt-[230px] pb-[7px] md:pb-[16px] px-1 md:px-3">
                        <div className="inline-flex flex-row items-center gap-x-1">
                            <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.3693 4.91284L13.0178 4.28619C13.2698 4.04267 13.2698 3.64783 13.0178 3.40444C12.7658 3.16093 12.3573 3.16093 12.1053 3.40444L11.4567 4.03109C10.3687 3.15507 9.04646 2.62585 7.63606 2.50196V1.24708H8.25986C8.61625 1.24708 8.90512 0.967837 8.90512 0.623479C8.90512 0.27912 8.61625 0 8.25986 0H5.72162C5.36523 0 5.07636 0.27912 5.07636 0.623479C5.07636 0.967837 5.36523 1.24708 5.72162 1.24708H6.34542V2.50196C2.81487 2.81218 0 5.67801 0 9.22805C0 12.9614 3.12658 15.9829 6.9908 15.9829C10.8544 15.9829 13.9815 12.9618 13.9815 9.22805C13.9815 7.63332 13.4131 6.12309 12.3693 4.91284ZM6.99068 14.7358C3.84769 14.7358 1.29051 12.2651 1.29051 9.22805C1.29051 6.19113 3.84769 3.72039 6.99068 3.72039C10.1338 3.72039 12.6908 6.19113 12.6908 9.22805C12.6908 12.2651 10.1338 14.7358 6.99068 14.7358ZM9.98122 6.33856C10.2332 6.58207 10.2332 6.97691 9.98122 7.22031L7.44701 9.66898C7.19499 9.9125 6.78636 9.9125 6.53447 9.66898C6.28245 9.42547 6.28245 9.03063 6.53447 8.78723L9.06855 6.33856C9.32057 6.09504 9.7292 6.09504 9.98122 6.33856Z" fill="#929292"/>
                            </svg>
                            <span className="font-['Roboto Flex'] font-normal text-xs text-[#929292]">1 мин.</span>
                        </div>
                        <div>
                            <h2 className="font-['Inter'] font-bold uppercase text-black text-[10px] md:text-[21px] lg:text-[22.7px]">Как зарабатывать на продаже аккаунтов Facebook</h2>
                        </div>
                    </div>
                    <div className="md:grid-cols-2 grid-cols-1 grid mt-[10px] md:mt-[25px] lg:mt-[30px] gap-y-[10px] md:gap-[28px] lg:gap-[30px]">
                        <div className="p-[11px] bg-white rounded-[20px]">
                            <div className="mb-2.5">
                                <img src="/blogstatic/adheart.png" alt="AdHeart Blog Post" draggable="false" className="select-none rounded-[20px]" />
                            </div>
                            <div className="inline-flex flex-row items-center gap-x-1">
                                <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.3693 4.91284L13.0178 4.28619C13.2698 4.04267 13.2698 3.64783 13.0178 3.40444C12.7658 3.16093 12.3573 3.16093 12.1053 3.40444L11.4567 4.03109C10.3687 3.15507 9.04646 2.62585 7.63606 2.50196V1.24708H8.25986C8.61625 1.24708 8.90512 0.967837 8.90512 0.623479C8.90512 0.27912 8.61625 0 8.25986 0H5.72162C5.36523 0 5.07636 0.27912 5.07636 0.623479C5.07636 0.967837 5.36523 1.24708 5.72162 1.24708H6.34542V2.50196C2.81487 2.81218 0 5.67801 0 9.22805C0 12.9614 3.12658 15.9829 6.9908 15.9829C10.8544 15.9829 13.9815 12.9618 13.9815 9.22805C13.9815 7.63332 13.4131 6.12309 12.3693 4.91284ZM6.99068 14.7358C3.84769 14.7358 1.29051 12.2651 1.29051 9.22805C1.29051 6.19113 3.84769 3.72039 6.99068 3.72039C10.1338 3.72039 12.6908 6.19113 12.6908 9.22805C12.6908 12.2651 10.1338 14.7358 6.99068 14.7358ZM9.98122 6.33856C10.2332 6.58207 10.2332 6.97691 9.98122 7.22031L7.44701 9.66898C7.19499 9.9125 6.78636 9.9125 6.53447 9.66898C6.28245 9.42547 6.28245 9.03063 6.53447 8.78723L9.06855 6.33856C9.32057 6.09504 9.7292 6.09504 9.98122 6.33856Z" fill="#929292"/>
                                </svg>
                                <span className="font-['Roboto Flex'] font-normal text-xs text-[#929292]">3 мин.</span>
                            </div>
                            <div>
                                <h2 className="font-['Inter'] font-bold uppercase text-black text-[16.27px] md:text-[16.46px] lg:text-[17.3px]">Складчина adheart где купить аккаунт дешево?</h2>
                            </div>
                        </div>
                        <div className="p-[11px] bg-white rounded-[20px]">
                            <div className="mb-2.5">
                                <img src="/blogstatic/chatgpt.png" alt="ChatGPT Blog Post" draggable="false" className="select-none rounded-[20px]" />
                            </div>
                            <div className="inline-flex flex-row items-center gap-x-1">
                                <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.3693 4.91284L13.0178 4.28619C13.2698 4.04267 13.2698 3.64783 13.0178 3.40444C12.7658 3.16093 12.3573 3.16093 12.1053 3.40444L11.4567 4.03109C10.3687 3.15507 9.04646 2.62585 7.63606 2.50196V1.24708H8.25986C8.61625 1.24708 8.90512 0.967837 8.90512 0.623479C8.90512 0.27912 8.61625 0 8.25986 0H5.72162C5.36523 0 5.07636 0.27912 5.07636 0.623479C5.07636 0.967837 5.36523 1.24708 5.72162 1.24708H6.34542V2.50196C2.81487 2.81218 0 5.67801 0 9.22805C0 12.9614 3.12658 15.9829 6.9908 15.9829C10.8544 15.9829 13.9815 12.9618 13.9815 9.22805C13.9815 7.63332 13.4131 6.12309 12.3693 4.91284ZM6.99068 14.7358C3.84769 14.7358 1.29051 12.2651 1.29051 9.22805C1.29051 6.19113 3.84769 3.72039 6.99068 3.72039C10.1338 3.72039 12.6908 6.19113 12.6908 9.22805C12.6908 12.2651 10.1338 14.7358 6.99068 14.7358ZM9.98122 6.33856C10.2332 6.58207 10.2332 6.97691 9.98122 7.22031L7.44701 9.66898C7.19499 9.9125 6.78636 9.9125 6.53447 9.66898C6.28245 9.42547 6.28245 9.03063 6.53447 8.78723L9.06855 6.33856C9.32057 6.09504 9.7292 6.09504 9.98122 6.33856Z" fill="#929292"/>
                                </svg>
                                <span className="font-['Roboto Flex'] font-normal text-xs text-[#929292]">2 мин.</span>
                            </div>
                            <div>
                                <h2 className="font-['Inter'] font-bold uppercase text-black text-[16.27px] md:text-[16.46px] lg:text-[17.3px]">Складчина Chatgpt где купить аккаунт дешево?</h2>
                            </div>
                        </div>
                        <div className="p-[11px] bg-white rounded-[20px]">
                            <div className="mb-2.5">
                                <img src="/blogstatic/midjourney.png" alt="Midjourney Blog Post" draggable="false" className="select-none rounded-[20px]" />
                            </div>
                            <div className="inline-flex flex-row items-center gap-x-1">
                                <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.3693 4.91284L13.0178 4.28619C13.2698 4.04267 13.2698 3.64783 13.0178 3.40444C12.7658 3.16093 12.3573 3.16093 12.1053 3.40444L11.4567 4.03109C10.3687 3.15507 9.04646 2.62585 7.63606 2.50196V1.24708H8.25986C8.61625 1.24708 8.90512 0.967837 8.90512 0.623479C8.90512 0.27912 8.61625 0 8.25986 0H5.72162C5.36523 0 5.07636 0.27912 5.07636 0.623479C5.07636 0.967837 5.36523 1.24708 5.72162 1.24708H6.34542V2.50196C2.81487 2.81218 0 5.67801 0 9.22805C0 12.9614 3.12658 15.9829 6.9908 15.9829C10.8544 15.9829 13.9815 12.9618 13.9815 9.22805C13.9815 7.63332 13.4131 6.12309 12.3693 4.91284ZM6.99068 14.7358C3.84769 14.7358 1.29051 12.2651 1.29051 9.22805C1.29051 6.19113 3.84769 3.72039 6.99068 3.72039C10.1338 3.72039 12.6908 6.19113 12.6908 9.22805C12.6908 12.2651 10.1338 14.7358 6.99068 14.7358ZM9.98122 6.33856C10.2332 6.58207 10.2332 6.97691 9.98122 7.22031L7.44701 9.66898C7.19499 9.9125 6.78636 9.9125 6.53447 9.66898C6.28245 9.42547 6.28245 9.03063 6.53447 8.78723L9.06855 6.33856C9.32057 6.09504 9.7292 6.09504 9.98122 6.33856Z" fill="#929292"/>
                                </svg>
                                <span className="font-['Roboto Flex'] font-normal text-xs text-[#929292]">4 мин.</span>
                            </div>
                            <div>
                                <h2 className="font-['Inter'] font-bold uppercase text-black text-[16.27px] md:text-[16.46px] lg:text-[17.3px]">Складчина midjourney где купить аккаунт дешево?</h2>
                            </div>
                        </div>
                        <div className="p-[11px] bg-white rounded-[20px]">
                            <div className="mb-2.5">
                                <img src="/blogstatic/tgstat.png" alt="TGstat Blog Post" draggable="false" className="select-none rounded-[20px]" />
                            </div>
                            <div className="inline-flex flex-row items-center gap-x-1">
                                <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.3693 4.91284L13.0178 4.28619C13.2698 4.04267 13.2698 3.64783 13.0178 3.40444C12.7658 3.16093 12.3573 3.16093 12.1053 3.40444L11.4567 4.03109C10.3687 3.15507 9.04646 2.62585 7.63606 2.50196V1.24708H8.25986C8.61625 1.24708 8.90512 0.967837 8.90512 0.623479C8.90512 0.27912 8.61625 0 8.25986 0H5.72162C5.36523 0 5.07636 0.27912 5.07636 0.623479C5.07636 0.967837 5.36523 1.24708 5.72162 1.24708H6.34542V2.50196C2.81487 2.81218 0 5.67801 0 9.22805C0 12.9614 3.12658 15.9829 6.9908 15.9829C10.8544 15.9829 13.9815 12.9618 13.9815 9.22805C13.9815 7.63332 13.4131 6.12309 12.3693 4.91284ZM6.99068 14.7358C3.84769 14.7358 1.29051 12.2651 1.29051 9.22805C1.29051 6.19113 3.84769 3.72039 6.99068 3.72039C10.1338 3.72039 12.6908 6.19113 12.6908 9.22805C12.6908 12.2651 10.1338 14.7358 6.99068 14.7358ZM9.98122 6.33856C10.2332 6.58207 10.2332 6.97691 9.98122 7.22031L7.44701 9.66898C7.19499 9.9125 6.78636 9.9125 6.53447 9.66898C6.28245 9.42547 6.28245 9.03063 6.53447 8.78723L9.06855 6.33856C9.32057 6.09504 9.7292 6.09504 9.98122 6.33856Z" fill="#929292"/>
                                </svg>
                                <span className="font-['Roboto Flex'] font-normal text-xs text-[#929292]">2 мин.</span>
                            </div>
                            <div>
                                <h2 className="font-['Inter'] font-bold uppercase text-black text-[16.27px] md:text-[16.46px] lg:text-[17.3px]">Складчина tgstat где купить аккаунт дешево?</h2>
                            </div>
                        </div>
                        <div className="p-[11px] bg-white rounded-[20px]">
                            <div className="mb-2.5">
                                <img src="/blogstatic/canva.png" alt="Canva Blog Post" draggable="false" className="select-none rounded-[20px]" />
                            </div>
                            <div className="inline-flex flex-row items-center gap-x-1">
                                <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.3693 4.91284L13.0178 4.28619C13.2698 4.04267 13.2698 3.64783 13.0178 3.40444C12.7658 3.16093 12.3573 3.16093 12.1053 3.40444L11.4567 4.03109C10.3687 3.15507 9.04646 2.62585 7.63606 2.50196V1.24708H8.25986C8.61625 1.24708 8.90512 0.967837 8.90512 0.623479C8.90512 0.27912 8.61625 0 8.25986 0H5.72162C5.36523 0 5.07636 0.27912 5.07636 0.623479C5.07636 0.967837 5.36523 1.24708 5.72162 1.24708H6.34542V2.50196C2.81487 2.81218 0 5.67801 0 9.22805C0 12.9614 3.12658 15.9829 6.9908 15.9829C10.8544 15.9829 13.9815 12.9618 13.9815 9.22805C13.9815 7.63332 13.4131 6.12309 12.3693 4.91284ZM6.99068 14.7358C3.84769 14.7358 1.29051 12.2651 1.29051 9.22805C1.29051 6.19113 3.84769 3.72039 6.99068 3.72039C10.1338 3.72039 12.6908 6.19113 12.6908 9.22805C12.6908 12.2651 10.1338 14.7358 6.99068 14.7358ZM9.98122 6.33856C10.2332 6.58207 10.2332 6.97691 9.98122 7.22031L7.44701 9.66898C7.19499 9.9125 6.78636 9.9125 6.53447 9.66898C6.28245 9.42547 6.28245 9.03063 6.53447 8.78723L9.06855 6.33856C9.32057 6.09504 9.7292 6.09504 9.98122 6.33856Z" fill="#929292"/>
                                </svg>
                                <span className="font-['Roboto Flex'] font-normal text-xs text-[#929292]">2 мин.</span>
                            </div>
                            <div>
                                <h2 className="font-['Inter'] font-bold uppercase text-black text-[16.27px] md:text-[16.46px] lg:text-[17.3px]">Складчина canva где купить аккаунт дешево?</h2>
                            </div>
                        </div>
                        <div className="p-[11px] bg-white rounded-[20px]">
                            <div className="mb-2.5">
                                <img src="/blogstatic/freepik.png" alt="FreePik Blog Post" draggable="false" className="select-none rounded-[20px]" />
                            </div>
                            <div className="inline-flex flex-row items-center gap-x-1">
                                <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.3693 4.91284L13.0178 4.28619C13.2698 4.04267 13.2698 3.64783 13.0178 3.40444C12.7658 3.16093 12.3573 3.16093 12.1053 3.40444L11.4567 4.03109C10.3687 3.15507 9.04646 2.62585 7.63606 2.50196V1.24708H8.25986C8.61625 1.24708 8.90512 0.967837 8.90512 0.623479C8.90512 0.27912 8.61625 0 8.25986 0H5.72162C5.36523 0 5.07636 0.27912 5.07636 0.623479C5.07636 0.967837 5.36523 1.24708 5.72162 1.24708H6.34542V2.50196C2.81487 2.81218 0 5.67801 0 9.22805C0 12.9614 3.12658 15.9829 6.9908 15.9829C10.8544 15.9829 13.9815 12.9618 13.9815 9.22805C13.9815 7.63332 13.4131 6.12309 12.3693 4.91284ZM6.99068 14.7358C3.84769 14.7358 1.29051 12.2651 1.29051 9.22805C1.29051 6.19113 3.84769 3.72039 6.99068 3.72039C10.1338 3.72039 12.6908 6.19113 12.6908 9.22805C12.6908 12.2651 10.1338 14.7358 6.99068 14.7358ZM9.98122 6.33856C10.2332 6.58207 10.2332 6.97691 9.98122 7.22031L7.44701 9.66898C7.19499 9.9125 6.78636 9.9125 6.53447 9.66898C6.28245 9.42547 6.28245 9.03063 6.53447 8.78723L9.06855 6.33856C9.32057 6.09504 9.7292 6.09504 9.98122 6.33856Z" fill="#929292"/>
                                </svg>
                                <span className="font-['Roboto Flex'] font-normal text-xs text-[#929292]">1 мин.</span>
                            </div>
                            <div>
                                <h2 className="font-['Inter'] font-bold uppercase text-black text-[16.27px] md:text-[16.46px] lg:text-[17.3px]">Складчина freepik где купить аккаунт дешево?</h2>
                            </div>
                        </div>
                        <div className="p-[11px] bg-white rounded-[20px]">
                            <div className="mb-2.5">
                                <img src="/blogstatic/envato.png" alt="Envato Elements Blog Post" draggable="false" className="select-none rounded-[20px]" />
                            </div>
                            <div className="inline-flex flex-row items-center gap-x-1">
                                <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.3693 4.91284L13.0178 4.28619C13.2698 4.04267 13.2698 3.64783 13.0178 3.40444C12.7658 3.16093 12.3573 3.16093 12.1053 3.40444L11.4567 4.03109C10.3687 3.15507 9.04646 2.62585 7.63606 2.50196V1.24708H8.25986C8.61625 1.24708 8.90512 0.967837 8.90512 0.623479C8.90512 0.27912 8.61625 0 8.25986 0H5.72162C5.36523 0 5.07636 0.27912 5.07636 0.623479C5.07636 0.967837 5.36523 1.24708 5.72162 1.24708H6.34542V2.50196C2.81487 2.81218 0 5.67801 0 9.22805C0 12.9614 3.12658 15.9829 6.9908 15.9829C10.8544 15.9829 13.9815 12.9618 13.9815 9.22805C13.9815 7.63332 13.4131 6.12309 12.3693 4.91284ZM6.99068 14.7358C3.84769 14.7358 1.29051 12.2651 1.29051 9.22805C1.29051 6.19113 3.84769 3.72039 6.99068 3.72039C10.1338 3.72039 12.6908 6.19113 12.6908 9.22805C12.6908 12.2651 10.1338 14.7358 6.99068 14.7358ZM9.98122 6.33856C10.2332 6.58207 10.2332 6.97691 9.98122 7.22031L7.44701 9.66898C7.19499 9.9125 6.78636 9.9125 6.53447 9.66898C6.28245 9.42547 6.28245 9.03063 6.53447 8.78723L9.06855 6.33856C9.32057 6.09504 9.7292 6.09504 9.98122 6.33856Z" fill="#929292"/>
                                </svg>
                                <span className="font-['Roboto Flex'] font-normal text-xs text-[#929292]">3 мин.</span>
                            </div>
                            <div>
                                <h2 className="font-['Inter'] font-bold uppercase text-black text-[16.27px] md:text-[16.46px] lg:text-[17.3px]">Складчина envato elements где купить аккаунт дешево?</h2>
                            </div>
                        </div>
                        <div className="p-[11px] bg-white rounded-[20px]">
                            <div className="mb-2.5">
                                <img src="/blogstatic/leonardoai.png" alt="Leonardo AI Blog Post" draggable="false" className="select-none rounded-[20px]" />
                            </div>
                            <div className="inline-flex flex-row items-center gap-x-1">
                                <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.3693 4.91284L13.0178 4.28619C13.2698 4.04267 13.2698 3.64783 13.0178 3.40444C12.7658 3.16093 12.3573 3.16093 12.1053 3.40444L11.4567 4.03109C10.3687 3.15507 9.04646 2.62585 7.63606 2.50196V1.24708H8.25986C8.61625 1.24708 8.90512 0.967837 8.90512 0.623479C8.90512 0.27912 8.61625 0 8.25986 0H5.72162C5.36523 0 5.07636 0.27912 5.07636 0.623479C5.07636 0.967837 5.36523 1.24708 5.72162 1.24708H6.34542V2.50196C2.81487 2.81218 0 5.67801 0 9.22805C0 12.9614 3.12658 15.9829 6.9908 15.9829C10.8544 15.9829 13.9815 12.9618 13.9815 9.22805C13.9815 7.63332 13.4131 6.12309 12.3693 4.91284ZM6.99068 14.7358C3.84769 14.7358 1.29051 12.2651 1.29051 9.22805C1.29051 6.19113 3.84769 3.72039 6.99068 3.72039C10.1338 3.72039 12.6908 6.19113 12.6908 9.22805C12.6908 12.2651 10.1338 14.7358 6.99068 14.7358ZM9.98122 6.33856C10.2332 6.58207 10.2332 6.97691 9.98122 7.22031L7.44701 9.66898C7.19499 9.9125 6.78636 9.9125 6.53447 9.66898C6.28245 9.42547 6.28245 9.03063 6.53447 8.78723L9.06855 6.33856C9.32057 6.09504 9.7292 6.09504 9.98122 6.33856Z" fill="#929292"/>
                                </svg>
                                <span className="font-['Roboto Flex'] font-normal text-xs text-[#929292]">3 мин.</span>
                            </div>
                            <div>
                                <h2 className="font-['Inter'] font-bold uppercase text-black text-[16.27px] md:text-[16.46px] lg:text-[17.3px]">Складчина leonardo ai где купить аккаунт дешево?</h2>
                            </div>
                        </div>
                        <div className="p-[11px] bg-white rounded-[20px]">
                            <div className="mb-2.5">
                                <img src="/blogstatic/pipiads.png" alt="PiPiAds Blog Post" draggable="false" className="select-none rounded-[20px]" />
                            </div>
                            <div className="inline-flex flex-row items-center gap-x-1">
                                <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.3693 4.91284L13.0178 4.28619C13.2698 4.04267 13.2698 3.64783 13.0178 3.40444C12.7658 3.16093 12.3573 3.16093 12.1053 3.40444L11.4567 4.03109C10.3687 3.15507 9.04646 2.62585 7.63606 2.50196V1.24708H8.25986C8.61625 1.24708 8.90512 0.967837 8.90512 0.623479C8.90512 0.27912 8.61625 0 8.25986 0H5.72162C5.36523 0 5.07636 0.27912 5.07636 0.623479C5.07636 0.967837 5.36523 1.24708 5.72162 1.24708H6.34542V2.50196C2.81487 2.81218 0 5.67801 0 9.22805C0 12.9614 3.12658 15.9829 6.9908 15.9829C10.8544 15.9829 13.9815 12.9618 13.9815 9.22805C13.9815 7.63332 13.4131 6.12309 12.3693 4.91284ZM6.99068 14.7358C3.84769 14.7358 1.29051 12.2651 1.29051 9.22805C1.29051 6.19113 3.84769 3.72039 6.99068 3.72039C10.1338 3.72039 12.6908 6.19113 12.6908 9.22805C12.6908 12.2651 10.1338 14.7358 6.99068 14.7358ZM9.98122 6.33856C10.2332 6.58207 10.2332 6.97691 9.98122 7.22031L7.44701 9.66898C7.19499 9.9125 6.78636 9.9125 6.53447 9.66898C6.28245 9.42547 6.28245 9.03063 6.53447 8.78723L9.06855 6.33856C9.32057 6.09504 9.7292 6.09504 9.98122 6.33856Z" fill="#929292"/>
                                </svg>
                                <span className="font-['Roboto Flex'] font-normal text-xs text-[#929292]">2 мин.</span>
                            </div>
                            <div>
                                <h2 className="font-['Inter'] font-bold uppercase text-black text-[16.27px] md:text-[16.46px] lg:text-[17.3px]">Складчина pipiads где купить аккаунт дешево?</h2>
                            </div>
                        </div>
                        <div className="p-[11px] bg-white rounded-[20px]">
                            <div className="mb-2.5">
                                <img src="/blogstatic/telegramcombo.png" alt="Telemetr Blog Post" draggable="false" className="select-none rounded-[20px]" />
                            </div>
                            <div className="inline-flex flex-row items-center gap-x-1">
                                <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.3693 4.91284L13.0178 4.28619C13.2698 4.04267 13.2698 3.64783 13.0178 3.40444C12.7658 3.16093 12.3573 3.16093 12.1053 3.40444L11.4567 4.03109C10.3687 3.15507 9.04646 2.62585 7.63606 2.50196V1.24708H8.25986C8.61625 1.24708 8.90512 0.967837 8.90512 0.623479C8.90512 0.27912 8.61625 0 8.25986 0H5.72162C5.36523 0 5.07636 0.27912 5.07636 0.623479C5.07636 0.967837 5.36523 1.24708 5.72162 1.24708H6.34542V2.50196C2.81487 2.81218 0 5.67801 0 9.22805C0 12.9614 3.12658 15.9829 6.9908 15.9829C10.8544 15.9829 13.9815 12.9618 13.9815 9.22805C13.9815 7.63332 13.4131 6.12309 12.3693 4.91284ZM6.99068 14.7358C3.84769 14.7358 1.29051 12.2651 1.29051 9.22805C1.29051 6.19113 3.84769 3.72039 6.99068 3.72039C10.1338 3.72039 12.6908 6.19113 12.6908 9.22805C12.6908 12.2651 10.1338 14.7358 6.99068 14.7358ZM9.98122 6.33856C10.2332 6.58207 10.2332 6.97691 9.98122 7.22031L7.44701 9.66898C7.19499 9.9125 6.78636 9.9125 6.53447 9.66898C6.28245 9.42547 6.28245 9.03063 6.53447 8.78723L9.06855 6.33856C9.32057 6.09504 9.7292 6.09504 9.98122 6.33856Z" fill="#929292"/>
                                </svg>
                                <span className="font-['Roboto Flex'] font-normal text-xs text-[#929292]">3 мин.</span>
                            </div>
                            <div>
                                <h2 className="font-['Inter'] font-bold uppercase text-black text-[16.27px] md:text-[16.46px] lg:text-[17.3px]">Складчина telemetr где купить аккаунт дешево?</h2>
                            </div>
                        </div>
                        <div className="p-[11px] bg-white rounded-[20px]">
                            <div className="mb-2.5">
                                <img src="/blogstatic/serpstat.png" alt="serpstat Blog Post" draggable="false" className="select-none rounded-[20px]" />
                            </div>
                            <div className="inline-flex flex-row items-center gap-x-1">
                                <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.3693 4.91284L13.0178 4.28619C13.2698 4.04267 13.2698 3.64783 13.0178 3.40444C12.7658 3.16093 12.3573 3.16093 12.1053 3.40444L11.4567 4.03109C10.3687 3.15507 9.04646 2.62585 7.63606 2.50196V1.24708H8.25986C8.61625 1.24708 8.90512 0.967837 8.90512 0.623479C8.90512 0.27912 8.61625 0 8.25986 0H5.72162C5.36523 0 5.07636 0.27912 5.07636 0.623479C5.07636 0.967837 5.36523 1.24708 5.72162 1.24708H6.34542V2.50196C2.81487 2.81218 0 5.67801 0 9.22805C0 12.9614 3.12658 15.9829 6.9908 15.9829C10.8544 15.9829 13.9815 12.9618 13.9815 9.22805C13.9815 7.63332 13.4131 6.12309 12.3693 4.91284ZM6.99068 14.7358C3.84769 14.7358 1.29051 12.2651 1.29051 9.22805C1.29051 6.19113 3.84769 3.72039 6.99068 3.72039C10.1338 3.72039 12.6908 6.19113 12.6908 9.22805C12.6908 12.2651 10.1338 14.7358 6.99068 14.7358ZM9.98122 6.33856C10.2332 6.58207 10.2332 6.97691 9.98122 7.22031L7.44701 9.66898C7.19499 9.9125 6.78636 9.9125 6.53447 9.66898C6.28245 9.42547 6.28245 9.03063 6.53447 8.78723L9.06855 6.33856C9.32057 6.09504 9.7292 6.09504 9.98122 6.33856Z" fill="#929292"/>
                                </svg>
                                <span className="font-['Roboto Flex'] font-normal text-xs text-[#929292]">2 мин.</span>
                            </div>
                            <div>
                                <h2 className="font-['Inter'] font-bold uppercase text-black text-[16.27px] md:text-[16.46px] lg:text-[17.3px]">Складчина serpstat где купить аккаунт дешево?</h2>
                            </div>
                        </div>
                        <div className="p-[11px] bg-white rounded-[20px]">
                            <div className="mb-2.5">
                                <img src="/blogstatic/segmentotarget.png" alt="Segmento Target Blog Post" draggable="false" className="select-none rounded-[20px]" />
                            </div>
                            <div className="inline-flex flex-row items-center gap-x-1">
                                <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.3693 4.91284L13.0178 4.28619C13.2698 4.04267 13.2698 3.64783 13.0178 3.40444C12.7658 3.16093 12.3573 3.16093 12.1053 3.40444L11.4567 4.03109C10.3687 3.15507 9.04646 2.62585 7.63606 2.50196V1.24708H8.25986C8.61625 1.24708 8.90512 0.967837 8.90512 0.623479C8.90512 0.27912 8.61625 0 8.25986 0H5.72162C5.36523 0 5.07636 0.27912 5.07636 0.623479C5.07636 0.967837 5.36523 1.24708 5.72162 1.24708H6.34542V2.50196C2.81487 2.81218 0 5.67801 0 9.22805C0 12.9614 3.12658 15.9829 6.9908 15.9829C10.8544 15.9829 13.9815 12.9618 13.9815 9.22805C13.9815 7.63332 13.4131 6.12309 12.3693 4.91284ZM6.99068 14.7358C3.84769 14.7358 1.29051 12.2651 1.29051 9.22805C1.29051 6.19113 3.84769 3.72039 6.99068 3.72039C10.1338 3.72039 12.6908 6.19113 12.6908 9.22805C12.6908 12.2651 10.1338 14.7358 6.99068 14.7358ZM9.98122 6.33856C10.2332 6.58207 10.2332 6.97691 9.98122 7.22031L7.44701 9.66898C7.19499 9.9125 6.78636 9.9125 6.53447 9.66898C6.28245 9.42547 6.28245 9.03063 6.53447 8.78723L9.06855 6.33856C9.32057 6.09504 9.7292 6.09504 9.98122 6.33856Z" fill="#929292"/>
                                </svg>
                                <span className="font-['Roboto Flex'] font-normal text-xs text-[#929292]">3 мин.</span>
                            </div>
                            <div>
                                <h2 className="font-['Inter'] font-bold uppercase text-black text-[16.27px] md:text-[16.46px] lg:text-[17.3px]">Складчина segmento target где купить аккаунт дешево?</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-span-1 col-start-4 hidden lg:flex justify-end pt-[25px]">
                    <div>
                        <img src="/adstatic/adplacebanner.png" alt="Skladchina.me Ad Banner" draggable="false" className="select-none max-w-[240px]" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Index;