import React from "react";
import Marquee from "react-fast-marquee";

const Redline: React.FC = () => {
    return (
        <Marquee autoFill={true} className="w-full bg-[#F53C3A] py-2.5 lg:py-[15px] z-20">
            <span className="font-['Montserrat'] text-black font-medium text-base px-[30px]">Вышла новая статья! Бегом читать!</span>
        </Marquee>
    )
}

export default Redline;