import React from "react";

const Footer: React.FC = () => {
    return (
        <>
            <footer className="w-full bg-white text-black text-center flex flex-col justify-center items-center py-[29px] mt-[145px]">
                <div>
                    <img src="/logo.svg" alt="Skladchina.me logo" draggable={false} className="select-none h-[45px]" />
                </div>
                <div className="py-[14px] px-[46px] rounded-full border border-black mt-[16px]">
                    <span className="font-['Roboto Flex'] font-medium text-base">2018-2024 Skladchina.me</span>
                </div>
            </footer>
        </>
    )
}

export default Footer;