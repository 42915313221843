import React from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import Index from "./pages/Index";
import Navbar from "./components/Navbar";
import Redline from "./components/Redline";
import Footer from "./components/Footer";

function App() {
  return (
    <BrowserRouter>
      <Redline />
      <Navbar/>
      <Routes>
        <Route path="/" element={<Index />} />
      </Routes>
      <Footer/>
    </BrowserRouter>
  );
}

export default App;