import React, {useState} from "react"
const Navbar: React.FC = () => {
    const [menuState, setMenuState] = useState(false);
    return (
        <>
            <header className="w-full flex justify-between flex-row items-center px-2.5 md:px-[27px] lg:px-[40px] skmeHeader z-20">
                <div>
                    <a href="#" className="bg-[#ED534B] rounded-full flex flex-row gap-x-2.5 items-center py-[7px] px-[12px] md:py-[12px] lg:py-[15px] md:px-[20px] lg:px-[27px]">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="size-2.5 md:size-4 lg:size-5">
                            <g clip-path="url(#clip0_1_14)">
                                <path d="M10.5183 0C5.31872 0 1.13863 4.2337 1.13863 9.5C1.13863 11.0489 1.54644 12.4946 2.15816 13.837L0.119096 17.6576C-0.288718 18.3804 0.424955 19.2065 1.13863 18.8967L5.62458 17.5543C7.05192 18.4837 8.78513 19 10.6203 19C15.8199 19 20 14.7663 20 9.5C20 4.2337 15.718 0 10.5183 0Z" fill="white"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_1_14">
                                    <rect width="20" height="20" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>
                        <span className="font-['Roboto Flex'] font-extrabold text-[7px] md:text-xs lg:text-sm text-white">Написать нам</span>
                    </a>
                </div>
                <div>
                    <img src="/logo.svg" alt="Skladchina.me logo header" draggable={false} className="-ml-2 lg:-ml-12 h-8 md:h-[53px] lg:h-[88px]" />
                </div>
                <div>
                    <button title="Mobile menu" className="lg:hidden" onClick={() => setMenuState(!menuState!)}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4 mt-1">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                        </svg>
                    </button>
                </div>
            </header>
            <nav className={menuState ? "w-full absolute top-0 h-full z-30 bg-white pt-7 px-6 flex flex-col gap-y-2.5" : "!hidden"}>
                <div className="flex flex-row items-center justify-between">
                    <div></div>
                    <div>
                        <button title="Mobile menu close" className="lg:hidden" onClick={() => setMenuState(!menuState!)}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                </div>
                <div className="bg-white py-[15px] pl-5 rounded-[20px] skmeMenuCard inline-block w-[290px]">
                    <span className="font-['Roboto Flex'] font-extrabold text-sm text-black select-none">Spy-сервисы</span>
                    <div className="px-3 pt-2.5 flex flex-col gap-y-2">
                        <a href="#" className="font-['Roboto Flex'] font-regular text-sm">AdHeart</a>
                        <a href="#" className="font-['Roboto Flex'] font-regular text-sm">PiPiAds</a>
                    </div>
                </div>
                <div className="bg-white py-[15px] pl-5 rounded-[20px] skmeMenuCard inline-block w-[290px]">
                    <span className="font-['Roboto Flex'] font-extrabold text-sm text-black select-none">Дизайн</span>
                    <div className="px-3 pt-2.5 flex flex-col gap-y-2">
                        <a href="#" className="font-['Roboto Flex'] font-regular text-sm">Envato Elements</a>
                        <a href="#" className="font-['Roboto Flex'] font-regular text-sm">FreePik</a>
                        <a href="#" className="font-['Roboto Flex'] font-regular text-sm">Supa</a>
                        <a href="#" className="font-['Roboto Flex'] font-regular text-sm">Canva</a>
                    </div>
                </div>
                <div className="bg-white py-[15px] pl-5 rounded-[20px] skmeMenuCard inline-block w-[290px]">
                    <span className="font-['Roboto Flex'] font-extrabold text-sm text-black select-none">Нейросети</span>
                    <div className="px-3 pt-2.5 flex flex-col gap-y-2">
                        <a href="#" className="font-['Roboto Flex'] font-regular text-sm">ChatGPT</a>
                        <a href="#" className="font-['Roboto Flex'] font-regular text-sm">Midjourney</a>
                        <a href="#" className="font-['Roboto Flex'] font-regular text-sm">Leonardo AI</a>
                    </div>
                </div>
                <div className="bg-white py-[15px] pl-5 rounded-[20px] skmeMenuCard inline-block w-[290px]">
                    <span className="font-['Roboto Flex'] font-extrabold text-sm text-black select-none">Telegram</span>
                    <div className="px-3 pt-2.5 flex flex-col gap-y-2">
                        <a href="#" className="font-['Roboto Flex'] font-regular text-sm">TGstat</a>
                        <a href="#" className="font-['Roboto Flex'] font-regular text-sm">Telemetr</a>
                    </div>
                </div>
                <div className="bg-white py-[15px] pl-5 rounded-[20px] skmeMenuCard inline-block w-[290px]">
                    <span className="font-['Roboto Flex'] font-extrabold text-sm text-black select-none">SEO</span>
                    <div className="px-3 pt-2.5 flex flex-col gap-y-2">
                        <a href="#" className="font-['Roboto Flex'] font-regular text-sm">SerpStat</a>
                        <a href="#" className="font-['Roboto Flex'] font-regular text-sm">Semrush</a>
                    </div>
                </div>
                <div className="bg-white py-[15px] pl-5 rounded-[20px] skmeMenuCard inline-block w-[290px]">
                    <span className="font-['Roboto Flex'] font-extrabold text-sm text-black select-none">Парсеры</span>
                    <div className="px-3 pt-2.5 flex flex-col gap-y-2">
                        <a href="#" className="font-['Roboto Flex'] font-regular text-sm">Segmento Target</a>
                    </div>
                </div>
                <div className="bg-white py-[15px] pl-5 w-[290px] rounded-[20px] skmeMenuCard inline-block">
                    <div className="px-3 flex flex-col gap-y-2">
                        <a href="#" className="font-['Roboto Flex'] font-regular text-sm">
                            <span className="font-['Roboto Flex'] font-extrabold text-sm text-black select-none">Реклама на Skladchik.me</span>
                        </a>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Navbar;